import React, { useEffect } from 'react';
import swal from 'sweetalert';

const DisableIdm = () => {
  const handleOkayClick = () => {
    window.history.back(); // Navigates back one page in the browser history
  };
  useEffect(() => {
    swal({
      title: "    علشان تتفرج علي الفيديو لازم تقفل اكستنشن `IDM` ",
      icon: "warning",
      button: "حسنا!",
      dangerMode: true,
    }).then(() => {
      handleOkayClick(); // Call the function to navigate back when "حسنا!" button is clicked

    });
  }, []);

  return <div className='disable-idm'></div>;
};

export default DisableIdm;
